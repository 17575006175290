<script lang="ts" setup>
import { ref } from 'vue'
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'
import ImageResponsive from '@/components/ImageResponsive.vue'

const navigation = ref([
  { name: 'Home', route: 'home' },
  { name: 'Music', route: 'music' },
  { name: 'Shows', route: 'shows' },
  { name: 'Shop', route: 'shop' }
])

const showNavigationModal = ref(false)

</script>

<template>
  <div class="bg-black relative z-10">
    <div class="mx-auto max-w-7xl px-2">
      <div class="relative flex h-16 items-center justify-between">
        <div class="flex flex-1 items-stretch justify-start">
          <div class="flex flex-grow items-left">
            <RouterLink to="home">
              <ImageResponsive alt="Disease Illusion Logo" class="h-10 w-10" image="rotellone"/>
            </RouterLink>
          </div>
          <div class="hidden sm:block ">
            <div class="flex space-x-4">
              <RouterLink
                v-for="link in navigation"
                :key="link.route"
                :to="{ name: link.route}"
                class="text-white uppercase font-bold px-3 py-2 text-sm"
              >
                {{ link.name }}
              </RouterLink>
            </div>
          </div>
          <div class="block sm:hidden">
            <div>
              <Bars3Icon class="fill-current size-10" @click="showNavigationModal = true"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="showNavigationModal" class="fixed inset-0 bg-black z-50">
    <div class="flex flex-col h-full justify-center items-center gap-12">
      <RouterLink
        v-for="link in navigation"
        :key="link.route"
        :to="{ name: link.route}"
        class="text-white uppercase font-bold px-3 py-2 text-2xl"
        @click="showNavigationModal = false"
      >
        {{ link.name }}
      </RouterLink>
      <XMarkIcon class="mt-10 size-10 text-white" @click="showNavigationModal = false"/>
    </div>
  </div>
</template>

<style lang="css" scoped>

</style>
