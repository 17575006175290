<script lang="ts" setup>
import { artist } from '@/data'
import ImageResponsive from '@/components/ImageResponsive.vue'
import { useHumanDuration } from '@/composables/useDate'
import useSlug from '@/composables/useSlug'

const props = defineProps<{
  albumId: string;
}>()

const album = artist.album.find(album => useSlug(album.name, true) === props.albumId)

if (!album) {
  throw new Error(`Album ${props.albumId} not found`)
}

</script>

<template>
  <div class="flex flex-wrap md:flex-nowrap justify-center gap-5">
    <div class="md:basis-1/3 basis-full">
      <ImageResponsive
        :alt="album.albumRelease.name"
        :image="album.image"
        :name="useSlug(album.albumRelease.name)"
        class="aspect-square w-full"
      />
    </div>
    <div class="md:basis-2/3 basis-full">
      <h2 class="text-xl font-bold">{{ album.name }}</h2>
      <ul>
        <li v-if="album.albumRelease.hasReleaseRegion" class="flex justify-between">
          <span>Release Date</span>
          <span>{{ album.albumRelease.hasReleaseRegion[0].releaseDate }}</span>
        </li>
        <li v-if="album.albumRelease.gtin" class="flex justify-between">
          <span>UPC</span>
          <span>{{ album.albumRelease.gtin }}</span>
        </li>
        <li v-if="album.albumRelease.recordLabel" class="flex justify-between">
          <span>Label</span>
          <span>{{ album.albumRelease.recordLabel.name }}</span>
        </li>
        <li v-if="album.albumRelease.catalogNumber" class="flex justify-between">
          <span>Catalog</span>
          <span>{{ album.albumRelease.catalogNumber }}</span>
        </li>
      </ul>
      <hr>
      <ul v-if="album.albumRelease.track && album.albumRelease.track.length > 0">
        <li v-for="track in album.albumRelease.track" :key="track.name" class="flex justify-between">
          <span>{{ track.name }}</span>
          <span>{{ useHumanDuration(track.duration).value }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>

</style>
