import { onMounted, onUnmounted } from 'vue'

const useScript = (src: string): void => {
  const script = document.createElement('script')
  script.setAttribute('src', src)
  script.async = true

  onMounted(() => document.head.appendChild(script))
  onUnmounted(() => document.head.removeChild(script))
}

export default useScript
