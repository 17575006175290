const useSlug = (text: string, compact = false): string => {
  let slug = text.toLowerCase()
  if (compact) {
    slug = slug.replace(/\(.+?\)/g, '').replace(/\s+/g, '').trim()
  }
  slug = slug.replace(/ /g, '_').replace(/[^a-z0-9_]/g, '')
  return slug
}

export default useSlug
