<script lang="ts" setup>
import NavbarComponent from '@/components/NavbarComponent.vue'
import FootComponent from '@/components/FootComponent.vue'

import { artist } from '@/data'
import { computed } from 'vue'
import SectionComponent from '@/components/SectionComponent.vue'
import ImageResponsive from '@/components/ImageResponsive.vue'
import HeadComponent from '@/components/HeadComponent.vue'
import logger from '@/plugins/logger'
import useSlug from '@/composables/useSlug'

const albums = computed(() => artist.album.filter(album => {
  logger.debug('Getting albums')
  return !album.albumReleaseType.includes('Single')
}).sort((a, b) => {
  return new Date(b.albumRelease.hasReleaseRegion[0].releaseDate).getTime() - new Date(a.albumRelease.hasReleaseRegion[0].releaseDate).getTime()
}))

</script>

<template>
  <NavbarComponent/>
  <HeadComponent compact/>
  <SectionComponent
    id="music"
    class="min-h-screen bg-stone-700"
  >
    <div class="flex flex-wrap justify-center gap-5">
      <div v-for="album in albums" :key="album.name" class="w-full sm:w-1/3">
        <RouterLink :to="{ name: 'album', params: { album: useSlug(album.albumRelease.name, true) } }">
          <ImageResponsive
            :alt="album.albumRelease.name"
            :image="album.image"
            :name="useSlug(album.albumRelease.name)"
            class="aspect-square"
          />
        </RouterLink>
        <h2 class="text-xl font-bold">{{ album.name }}</h2>
      </div>
    </div>
  </SectionComponent>
  <FootComponent/>
</template>
