import { computed, type ComputedRef } from 'vue'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)
dayjs.extend(duration)

export const useHumanDate = (futureAsString: string): ComputedRef<string> => computed(() => {
  const future = dayjs(futureAsString)
  const today = dayjs()
  if (today > future) {
    return 'now'
  }
  if (today.date() === future.date() - 1) {
    return 'tomorrow'
  }
  if (future.date() - today.date() < 5) {
    return `${future.fromNow()}`
  }
  return `on ${future.format('MMMM DD')}`
})

export const useHumanDuration = (duration: string): ComputedRef<string> => computed(() => {
  const time = dayjs.duration(duration)
  const hours = time.hours()
  const minutes = time.minutes()
  const seconds = time.seconds()
  if (hours > 0) {
    return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
  }
  return `${minutes}:${seconds.toString().padStart(2, '0')}`
})
