<script lang="ts" setup>
import Assets, { Asset, ResizedAsset } from '@/plugins/assets'
import type { Image, Thumbnail } from '@/data'
import { onMounted, ref } from 'vue'
import logger from '@/plugins/logger'

const props = defineProps<{
  image: string | Image;
  alt: string;
  class?: string;
  name?: string;
}>()

const src = ref<string>('')
const srcset = ref<string>('')
const sizes = ref<string>('')
const blurhashDataUrl = ref<string>('')
const isLocalAsset = ref<boolean>(true)
const imageLoaded = ref(false)

const localAsset = async (asset: Asset): Promise<void> => {
  logger.debug('Using local asset')
  isLocalAsset.value = true
  blurhashDataUrl.value = await asset.getBlurhashDataUrl()
  src.value = asset.getResized(300).getPath()
  const thumbnails = asset.getThumbnails()
  srcset.value = thumbnails.map((asset: ResizedAsset) => `${asset.getPath()} ${asset.getWidth()}w`).join(', ')
  sizes.value = thumbnails.map((asset: ResizedAsset) => `(max-width: ${asset.getWidth()}px) ${asset.getWidth()}px`).join(', ')
}

const remoteAsset = (image: Image): void => {
  logger.debug('Using remote asset')
  isLocalAsset.value = false
  src.value = image.contentUrl
  srcset.value = image.thumbnail.map((thumb: Thumbnail) => `${thumb.contentUrl} ${thumb.width}w`).join(', ')
  sizes.value = image.thumbnail.map((thumb: Thumbnail) => `(max-width: ${thumb.width}px) ${thumb.width}px`).join(', ')
}

onMounted(async () => {
  if (typeof props.image === 'string') {
    const asset = Assets.get(props.image)
    await localAsset(asset)
  }

  if (typeof props.image === 'object' && props.image['@type'].includes('ImageObject')) {
    try {
      if (!props.name) {
        throw new Error('Name is required for remote assets')
      }
      const asset = Assets.search(props.name)
      if (asset) {
        await localAsset(asset)
      } else {
        throw new Error('Local asset not found')
      }
    } catch (error) {
      logger.debug('Could not find local asset')
      remoteAsset(props.image)
    }
  }
})

</script>

<template>
  <img
    v-if="isLocalAsset"
    v-show="!imageLoaded"
    key="img"
    :alt="alt"
    :class="props.class ?? 'w-full h-full object-cover'"
    :src="blurhashDataUrl"
  />
  <img
    v-show="imageLoaded"
    key="img"
    :alt="alt"
    :class="props.class ?? 'w-full h-full object-cover'"
    :src="src"
    :srcset="srcset"
    :sizes="sizes"
    v-bind="$attrs"
    @load="imageLoaded = true"
  />
</template>

<style scoped>

</style>
