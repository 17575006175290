<script lang="ts" setup>
import { type Video, videos as videosDB } from '@/data'
import SectionComponent from '@/components/SectionComponent.vue'
import logger from '@/plugins/logger'
import { useHumanDate } from '@/composables/useDate'

const getVideos = (): Video[] => {
  logger.debug('Getting videos')
  const videos = []
  for (const video of videosDB) {
    if (video.visible && video.youtubeId) {
      const isPublished = video.published && new Date(video.published) < new Date()
      if (isPublished) {
        videos.push(video)
        continue
      }
      const hasPremiere = video.premiere
      if (hasPremiere) {
        const premiereDate = useHumanDate(video.published).value
        video.heading = `Watch the new video “${video.title}” ${premiereDate}`
        videos.push(video)
      }
    }
  }
  return videos
}
</script>

<template>
  <SectionComponent
    id="music"
    :opacity="0.3"
    background="polluted_ocean_1"
    background-class="bg-top bg-repeat bg-stone-600"
  >
    <div v-for="video in getVideos()" v-bind:key="video.youtubeId" class="m-auto p-4 w-full xl:w-2/3">
      <h3>{{ video.heading }}</h3>
      <iframe allowfullscreen class="w-full aspect-video shadow-xl shadow-black"
              v-bind:src="'https://www.youtube.com/embed/' + video.youtubeId"></iframe>
    </div>
  </SectionComponent>
</template>

<style>
</style>
