<script lang="ts" setup>
import NavbarComponent from '@/components/NavbarComponent.vue'
import SectionComponent from '@/components/SectionComponent.vue'
import HeadComponent from '@/components/HeadComponent.vue'
import { onMounted, ref } from 'vue'

const props = defineProps({
  url: {
    type: String,
    required: true
  },
  time: {
    type: Number,
    default: 1000
  }
})

const millisecondsLeft = ref(props.time)

const startCountdown = (): void => {
  const interval = setInterval(() => {
    millisecondsLeft.value -= 100
    if (millisecondsLeft.value <= 0) {
      clearInterval(interval)
      window.location.href = props.url
    }
  }, 100)
}

onMounted(() => {
  startCountdown()
})

</script>

<template>
  <NavbarComponent/>
  <HeadComponent compact/>
  <SectionComponent id="redirect">
    <p>
      Redirecting {{ millisecondsLeft > 1 ? 'in ' + millisecondsLeft : 'now' }}
    </p>
  </SectionComponent>
</template>
