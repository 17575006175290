<script lang="ts" setup>

import NavbarComponent from '@/components/NavbarComponent.vue'
import SectionComponent from '@/components/SectionComponent.vue'
import FootComponent from '@/components/FootComponent.vue'
import AlbumComponent from '@/components/AlbumComponent.vue'

import { useRoute } from 'vue-router'
import { computed } from 'vue'
import HeadComponent from '@/components/HeadComponent.vue'

const route = useRoute()
const albumId = route.params.album as string

const bgColor = computed(() => {
  switch (albumId) {
    case 'plasticocean':
      return 'bg-flamingo-800'
    case 'afterthestorm':
      return 'bg-stone-700'
    case 'backworld':
      return 'bg-emerald-950'
    case 'realitybehindtheillusionoflife':
      return 'bg-amber-950'
    default:
      return 'bg-stone-700'
  }
})

</script>

<template>
  <NavbarComponent/>
  <HeadComponent compact/>
  <SectionComponent
    id="album"
    class="min-h-screen bg-top bg-repeat"
    blend="multiply"
    :class="bgColor"
  >
    <AlbumComponent :albumId="albumId"/>
  </SectionComponent>
  <FootComponent/>
</template>

<style scoped>

</style>
