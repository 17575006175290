<script lang="ts" setup>
import SectionComponent from '@/components/SectionComponent.vue'
import useScript from '@/composables/useScript'

useScript('https://widget-app.songkick.com/injector/1332605')
</script>

<template>
  <SectionComponent
    id="shows"
    :parallax="true"
    background="rotellone_black_background"
    background-class="bg-cover bg-fixed bg-center bg-no-repeat"
    title="Upcoming Shows"
  >
    <a class="songkick-widget"
       data-button-bg-color="rgb(255,255,255,1)"
       data-button-text-color="rgb(0,0,0,1)"
       data-country-filter="off"
       data-detect-style="off"
       data-font-color="rgb(255,255,255,1)"
       data-locale="en"
       data-other-artists="on"
       data-past-events="on"
       data-past-events-offtour="on"
       data-remind-me="on"
       data-request-show="on"
       data-rsvp="on"
       data-share-button="on"
       data-theme="dark"
       data-track-button="on"
       href="https://www.songkick.com/artists/1332605">
    </a>
  </SectionComponent>
</template>

<style lang="css" scoped>
</style>
