import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router'
import Home from '@/views/HomeView.vue'
import NotFound from '@/views/NotFoundView.vue'
import Music from '@/views/MusicView.vue'
import Shows from '@/views/ShowsView.vue'
import Album from '@/views/AlbumView.vue'
import Redirect from '@/views/RedirectView.vue'

const views: RouteRecordRaw[] = [
  { path: '/', component: Home },
  { path: '/home', name: 'home', component: Home },
  { path: '/music', name: 'music', component: Music },
  { path: '/music/:album', name: 'album', component: Album },
  { path: '/shows', name: 'shows', component: Shows },
]

const redirects: RouteRecordRaw[] = [
  { path: '/shop', name: 'shop', redirect: 'https://www.freewebstore.org/disease-illusion-online-store' },
  {
    path: '/listen',
    name: 'listen',
    redirect: 'https://social.tunecore.com/linkShare?linkid=HFnyR7QcsOhWCxCXWeo2FA'
  },
  {
    path: '/realitybeyondtheillusionoflife',
    name: 'realitybeyondtheillusionoflife',
    redirect: 'https://ffm.to/realitybeyondtheillusionoflife'
  },
  { path: '/backworld', name: 'backworld', redirect: 'https://ffm.to/diseaseillusionbackworld' },
  { path: '/redwine', name: 'redwine', redirect: 'https://ffm.to/redwinestainedcheek' },
  { path: '/afterthestorm', name: 'afterthestorm', redirect: 'https://ffm.to/afterthestormthatnevercame' },
  {
    path: '/plasticocean',
    name: 'plasticocean',
    redirect: 'https://social.tunecore.com/linkShare?linkid=HFnyR7QcsOhWCxCXWeo2FA'
  },
  {
    path: '/iamtheenemy',
    name: 'iamtheenemy',
    redirect: 'https://social.tunecore.com/linkShare?linkid=fj6sQIIcr6KFMC-EXcGGFA'
  },
  {
    path: '/glassandsteel',
    name: 'glassandsteel',
    redirect: 'https://social.tunecore.com/linkShare?linkid=3raQUILp1YUc08D1w7RKUQ'
  },
  {
    path: '/invisible',
    name: 'invisible',
    redirect: 'https://social.tunecore.com/linkShare?linkid=GR1hoxZvbEx7XJTlaOI42A'
  },
  {
    path: '/no_ground',
    name: 'no_ground',
    redirect: 'https://open.spotify.com/artist/7r8uZKJDrwLyy3HoD90FKC'
  },
].map(route => {
  return {
    path: route.path,
    name: route.name,
    component: Redirect,
    props: {
      url: route.redirect,
    }
  }
})

const router = createRouter({
  history: createWebHistory(),
  routes: [
    ...views,
    ...redirects,
    { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound },
  ],
})

export default router
