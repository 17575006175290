import productsJSON from './products.json'
import socialsJSON from './socials.json'
import videosJSON from './videos.json'
import artistsJSON from './artist.json'

export type Product = {
  url: string;
  sku: string;
  name: string;
  price: number;
  currency: string;
  image: string;
};
export const products = productsJSON as unknown as {
  meta: {
    updated_at: string;
    expire_date: string;
    is_cache: boolean;
  };
  data: Product[];
}

export type Social = {
  name: string;
  url: string;
  musicService?: boolean;
};
export const socials = socialsJSON as unknown as Social[]

export type Video = {
  heading: string;
  title: string;
  youtubeId: string;
  published: string;
  visible: boolean;
  premiere?: boolean;
};
export const videos = videosJSON as unknown as Video[]

export type Member = {
  roleName: string;
  startDate: string;
  endDate: string;
  member: {
    '@type': string[];
    name: string;
    '@id': string;
  };
  '@type': string;
};

export type Track = {
  '@type': string;
  recordingOf: {
    '@type': string;
    name: string;
  };
  producer: {
    '@type': string[];
    name: string;
  };
  duration: string;
  isrcCode: string;
  name: string;
  contributor: {
    roleName: string;
    '@type': string;
    contributor: {
      '@type': string[];
      name: string;
    };
  }[];
  trackNumber: string;
};

export type Thumbnail = {
  '@type': string;
  contentUrl: string;
  encodingFormat: string;
  width: number;
};

export type Image = {
  '@type': string;
  contentUrl: string;
  representativeOfPage: string;
  encodingFormat: string;
  thumbnail: Thumbnail[];
};

export type Release = {
  name: string;
  '@id': string;
  '@context': string;
  '@type': string;
  releaseOf: {
    '@type': string;
    byArtist: {
      name: string;
      '@id': string;
      '@type': string;
    };
    name: string;
    '@id': string;
    albumProductionType: string;
    albumReleaseType: string;
    creditedTo: string;
  };
  duration: string;
  gtin: string;
  catalogNumber: string;
  recordLabel: {
    '@type': string;
    name: string;
  };
  track: Track[];
  musicReleaseFormat: string;
  image: Image;
  hasReleaseRegion: {
    releaseCountry: {
      '@type': string;
      '@id': string;
      name: string;
    };
    releaseDate: string;
    '@type': string;
  }[];
  creditedTo: string;
  inLanguage: string;
}

export type Album = {
  '@type': string;
  albumProductionType: string;
  albumReleaseType: string;
  creditedTo: string;
  name: string;
  image: Image;
  albumRelease: Release;
  '@context': string;
};

export const artist = artistsJSON as unknown as {
  '@type': string;
  '@context': string;
  name: string;
  sameAs: string[];
  groupOrigin: {
    '@id': string;
    containedIn: {
      containedIn: {
        '@type': string;
        containedIn: {
          name: string;
          '@id': string;
          '@type': string;
        };
        '@id': string;
        name: string;
      };
      '@id': string;
      name: string;
      '@type': string;
    };
    name: string;
    '@type': string;
  };
  member: Member[];
  album: Album[];
};
