<script lang="ts" setup>
import { artist, type Release } from '@/data'
import ImageResponsive from '@/components/ImageResponsive.vue'
import SectionComponent from '@/components/SectionComponent.vue'
import { computed } from 'vue'
import { MusicalNoteIcon, ShoppingCartIcon } from '@heroicons/vue/24/solid'
import logger from '@/plugins/logger'
import { useHumanDate } from '@/composables/useDate'
import useSlug from '@/composables/useSlug'

defineProps<{
  compact?: boolean;
}>()

const currentRelease = (releaseName: string): Release => computed(() => {
  logger.debug('Getting currentRelease')
  const album = artist.album.find(album => album.name === releaseName)
  const release = album?.albumRelease
  if (!release) {
    console.error(`Release ${releaseName} not found`, artist.album)
    throw new Error(`Release ${releaseName} not found`)
  }
  release.image = album?.image
  return release
}).value
const release = computed(() => currentRelease('No Ground'))
const outNow = useHumanDate(release.value.hasReleaseRegion[0].releaseDate)

// If 35-60 minutes or 7-29 tracks, it's an album
// If 15-22 minutes or 4-6 tracks, it's an EP
// If less than 10 minutes or 1-3 tracks, it's a single
const type = computed(() => {
  const duration = release.value.duration
  const tracks = release.value.track.length
  if ((duration >= 'PT35M' && duration <= 'PT60M') || (tracks >= 7 && tracks <= 29)) return 'album'
  if ((duration >= 'PT15M' && duration <= 'PT22M') || (tracks >= 4 && tracks <= 6)) return 'EP'
  if ((duration < 'PT10M') || (tracks >= 1 && tracks <= 3)) return 'single'
  return 'release'
})

</script>

<template>
  <SectionComponent
    id="home"
    :class="compact ? 'h-auto' : 'h-full sm:h-screen min-h-full'"
    :parallax="true"
    background="header_no_ground"
    background-class="bg-cover bg-no-repeat bg-center"
    class="w-screen min-w-full bg-black content-center"
  >
    <div class="text-center">
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-0 sm:gap-10 m-5 sm:m-10">
        <div
          :class="compact ? 'sm:max-w-52' : 'sm:max-w-96'"
          class="w-full ml-auto"
        >
          <ImageResponsive
            :alt="release.name + ' cover'"
            :image="release.image"
            :name="`releases/${release.name.toLowerCase()}`"
            class="shadow-xl shadow-amber-950 sm:w-full w-1/2 m-auto"/>
        </div>
        <div
          :class="compact ? 'sm:max-w-52' : 'sm:max-w-96'"
          class="w-full mr-auto content-center"
        >
          <div class="flex flex-wrap gap-0">
            <div class="w-full">
              <h2 :class="compact ? 'p-2' : 'p-5 text-3xl'">
                New {{ type }}<br><span v-if="release.name" class="font-semibold">“{{ release.name }}”</span><br>out
                {{ outNow }}
              </h2>
            </div>
            <div class="w-1/2">
              <RouterLink to="shop">
                <p :class="compact ? 'p-2' : 'p-5 text-2xl'"
                   class="bg-stone-700 hover:bg-stone-600 text-flamingo-500 hover:text-flamingo-500">
                  <ShoppingCartIcon class="size-5 inline-block"/>
                  Buy
                </p>
              </RouterLink>
            </div>
            <div class="w-1/2">
              <a :href="`/${useSlug(release.name)}`">
                <p :class="compact ? 'p-2' : 'p-5 text-2xl'"
                   class="bg-stone-700 hover:bg-stone-600 text-flamingo-500 hover:text-flamingo-500">
                  <MusicalNoteIcon class="size-5 inline-block"/>
                  Listen
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </SectionComponent>
</template>

<style lang="css" scoped>
</style>
